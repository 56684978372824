import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
// import BugsnagPerformance from '@bugsnag/browser-performance'
import { environment } from 'src/environments/environment'

if (environment.production) {
  Bugsnag.start({
    apiKey: environment.bugsnag,
    onError: (err) => {
      if (err.errors[0].errorClass.match('HttpErrorResponse|ChunkLoadError')) {
        // Prevent the error from being logged
        return false;
      }
      return true;
    }
  });
  // BugsnagPerformance.start({ apiKey: environment.bugsnag });
}

export function errorHandlerFactory() {
  return environment.production ? new BugsnagErrorHandler() : '';
}

@NgModule({
  imports: [CommonModule],
  providers: [
    environment.production ? { provide: ErrorHandler, useFactory: errorHandlerFactory } : []
  ]
})

export class ErrorHandlerModule { }
