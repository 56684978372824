<div class="wrap">
  <div class="content">
    <div class="left-section">
      <div class="logo">
        <div class="link" routerLink="/">
          <img class="brand-logo" ngSrc="{{vars.domain_details.logoLightBg}}" width="175" height="72" [alt]="vars.domain_details.name" priority />
        </div>
      </div>
    </div>
    <div class="right-section">
      <div class="btns">
        <button *ngIf="vars.pageLayoutConfig?.showProfile && !vars.isPermanentLoggedIn" class="signin-btn" (click)="openLogin(loginForm)" mat-ripple>Sign in</button>
        <button *ngIf="vars.pageLayoutConfig?.showProfile && vars.isPermanentLoggedIn" class="profile-icon" (click)="onProfileSidebarOpen()" mat-ripple>
          <img *ngIf="userData?.avtar?.cdn_path" ngSrc="{{userData?.avtar?.cdn_path}}" width="49" height="49" alt="{{userData?.full_name}}">
          <em *ngIf="!userData?.avtar?.cdn_path" class="material-icons">person</em>
        </button>

        <!-- <button *ngIf="!vars?.pageName?.match('all_fundraiser|stories')" class="go-all-fund" (click)="goToAllFundraiser()" mat-button>Contribute Now</button> -->
      </div>
    </div>
  </div>
</div>

<app-side-nav *ngIf="vars.isPermanentLoggedIn" />

<ng-template #loginForm>
  <app-component-loader *ngIf="!vars.isPermanentLoggedIn" selector="app-signin-signup" [inputs]="{showClose: true}" />
</ng-template>