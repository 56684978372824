import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: 'profile',
        loadComponent: () => import('./pages/profile/profile.component').then((c) => c.ProfileComponent),
        canActivate: [AuthGuard],
        data: { page_name: 'profile', origin: 'pf' }
      },
      {
        path: 'donations',
        loadComponent: () => import('./pages/my-donations/my-donations.component').then((c) => c.MyDonationsComponent),
        canActivate: [AuthGuard],
        data: { page_name: 'donations', origin: 'dn' }
      },
      {
        path: 'thankyou',
        loadComponent: () => import('./pages/thankyou/thankyou.component').then((c) => c.ThankyouComponent),
        data: { page_name: 'thank_you', origin: 'ty' }
      },
      {
        path: 'stories/:tag',
        loadComponent: () => import('./pages/story/story.component').then((c) => c.StoryComponent),
        data: { page_name: 'stories', origin: 's' }
      },
      {
        path: 'payment-route',
        loadComponent: () => import('./pages/payment-route/payment-route.component').then((c) => c.PaymentRouteComponent),
        data: { page_name: 'payment_redirect_page', origin: 'prp' }
      },
      {
        path: 'aboutus',
        loadComponent: () => import('./pages/aboutus/aboutus.component').then((c) => c.AboutusComponent),
        data: { page_name: 'aboutus', origin: 'au' }
      },
      {
        path: 'guarantee',
        loadComponent: () => import('./pages/guarantee/guarantee.component').then((c) => c.GuaranteeComponent),
        data: { page_name: 'guarantee', origin: 'gu' }
      },
      {
        path: 'signin',
        loadComponent: () => import('./core/signin-signup/signin-signup.component').then((c) => c.SigninSignupComponent),
        canActivate: [AuthGuard],
        data: { page_name: 'signin', origin: 'si' }
      },
      {
        path: 'success-stories',
        loadComponent: () => import('./pages/success-stories/success-stories.component').then((c) => c.SuccessStoriesComponent),
        data: { page_name: 'success_stories', origin: 'st' }
      },
      {
        path: 'success-story',
        loadComponent: () => import('./pages/success-stories/components/detail-success-story/detail-success-story.component').then((c) => c.DetailSuccessStoryComponent),
        data: { page_name: 'detail_success_story', origin: 'dst' }
      },
      {
        path: '404',
        loadComponent: () => import('./pages/not-found/not-found.component').then((c) => c.NotFoundComponent),
        data: { page_name: 'page_not_found', origin: '404' }
      },
      {
        path: '',
        loadComponent: () => import('./pages/home/home.component').then((c) => c.HomeComponent),
        pathMatch: 'full',
        data: { page_name: 'home', origin: 'h' }
      },
    ]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
