import { NgClass, NgFor, NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
// import { CurrencyDropdownComponent } from 'src/app/core/currency-dropdown/currency-dropdown.component';
import { IUser } from 'src/app/shared/model/user.model';
import { EventsService, UtilService, VariablesService } from 'src/app/shared/services';
import { ProfileProgressComponent } from "../../../core/profile-progress/profile-progress.component";
// import { PortfolioService } from 'src/app/shared/services/portfolio/portfolio.service';
// import { Router } from '@angular/router';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { ComponentLoader } from 'src/app/core/component-loader/component-loader.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgOptimizedImage,
    NgTemplateOutlet,
    NgClass,
    // CurrencyDropdownComponent,
    MatIconModule,
    MatRippleModule,
    ProfileProgressComponent,
    ComponentLoader,
    SideNavComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  constructor(
    private events: EventsService,
    public vars: VariablesService,
    private util: UtilService,
    // private storageService: StorageService,
    // private portfolioService: PortfolioService,
    // private router: Router,
    // private auth: AuthService
  ) { }
  userData?: IUser | any;
  profileSideState = false;
  // isDonorSummaryCalled: boolean = false;
  // livesImpactedList = [
  //   { id: 1, label: 'Donations done', value: '02' },
  //   { id: 2, label: 'Do good days', value: '22' }
  // ]
  // menuList = [
  //   {id: 1, label: 'My Donations', iconName: 'donation', redirectTo: 'donations'},
  //   {id: 2, label: 'FAQ\'s & Support', iconName: 'faq', redirectTo: 'aboutus'},
  //   // {id: 3, label: 'Download Tax Certificate', iconName: 'download-certificate',  redirectTo: ''},
  //   {id: 4, label: 'Logout', iconName: 'setting', redirectTo: ''},
  // ]

  ngOnInit() {
    this.getUserData();
    // this.vars.currency$.subscribe(res => {
    //   if (res && this.userData && Object.keys(this.userData).length>0) {
    //     this.getDonorSummary();
    //   }
    // });
    this.vars.sidebarDrawerState.subscribe(val => {
      if (this.vars.isBrowser) {
        this.profileSideState = val;
        document.body.style.overflowY = val  ? 'hidden': '';
      }
    });
  }

  // getDonorSummary() {
  //   if (this.isDonorSummaryCalled) { return; }
  //   this.isDonorSummaryCalled = true;
  //   this.portfolioService.getDonorSummary(`?currency=${this.vars.currency}`).subscribe(async (val: any) => {
  //     this.livesImpactedList[0].value = val?.data?.funds;
  //     await this.auth.getUserDataMeApi().then((val: any) => {
  //       this.userData = val;
  //       if (this.userData.first_order?.creation_date) {
  //         this.livesImpactedList[1].value = `${this.daysFromPresent(this.userData?.first_order?.creation_date)}`;
  //       } else {
  //         this.livesImpactedList[1].value = '0';
  //       }
  //     });
  //     // this.livesImpactedList[1].value = val?.data?.campaigns;
  //   })
  // }

  // daysFromPresent(inputDateString: any): any {
  //   const inputDate = new Date(inputDateString);
  //   const presentDate = new Date();
  //   const differenceMs = presentDate.getTime() - inputDate.getTime();
  //   const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  //   return Math.abs(differenceDays);
  // }

  // redirect() {
  //   if (this.vars.isBrowser) {
  //     const path = window.location.pathname;
  //     this.profileSideState = false;
  //     this.vars.sidebarDrawerState.next(false);
  //     document.body.style.overflowY = '';
  //     if (path === '/profile') {
  //       return;
  //     } else {
  //       this.router.navigate(['profile']);
  //     }
  //   }
  // }

  getUserData() {
    this.vars.userData$.subscribe((val: (IUser | undefined)) => {
      this.userData = val;
      // const userdata = this.util.getUserData();
      // if (userdata && this.vars.currency) {
      //   this.getDonorSummary();
      // }
    })
  }

  // redirectionLogic(item: any) {
  //   if (this.vars.isBrowser) {
  //     let a = document.createElement('a');
  //     // close drawer
  //     this.profileSideState = false;
  //     this.vars.sidebarDrawerState.next(false);
  //     document.body.style.overflowY = '';
  //     if (item.iconName === 'support') {
  //       a.href = 'https://customerhappiness.ketto.org/portal/en/kb';
  //       a.click();
  //     } else if (item.label === 'Logout') {
  //       this.logout();
  //       // removing dock on logout
  //     } else {
  //       this.util.router.navigate([item.redirectTo]);
  //     }
  //   }
  // }

  // logout() {
  //   this.storageService.delete('userdata');
  //   this.storageService.delete('user');
  //   this.storageService.deleteCookie('u_auth');
  //   this.storageService.deleteCookie('k_auth');
  //   this.vars.userData$.next(undefined);
  //   this.vars.isPermanentLoggedIn = false;
  //   this.vars.isTempLoggedIn = false;
  //   this.vars.isTempLoggedIn$.next(false);
  //   this.util.router.navigate(['/']);
  //   // this.authService.sessionLogout().subscribe({next: (res) => this.logoutUser(), error: () => this.logoutUser()});
  // }


  goToHome() {
    this.vars.sidebarDrawerState.next(false);
    document.body.style.overflowY = '';
    if (this.vars.pageLayoutConfig?.logoNavigate) {
      this.util.router.navigate(['/']);
    }
  }

  onProfileSidebarOpen() {
    this.profileSideState = !this.profileSideState;
    this.vars.sidebarDrawerState.next(this.profileSideState);
    if (this.vars.isBrowser) {
      if (this.profileSideState) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = '';
      }
    }
  }

  openLogin(form: any) {
    const dialogRef = this.util.dialog.open(form, {
      width: '100%',
      maxWidth: '741px',
      panelClass: 'signin-form'
    });

    this.events.sendSystemEvent({
      eventName: 'signin clicked',
      event_type: 'signin'
    });
  }
}
