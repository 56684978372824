export const Currency = [
    { code: 'IN', currency: 'INR', icon: 'icon-rupee', is_symbol: true, symbol: '&#x20b9;' },
    { code: 'US', currency: 'USD', icon: 'icon-dollar', is_symbol: true, symbol: '&#36;' },
    { code: 'GB', currency: 'GBP', icon: 'icon-pound', is_symbol: true, symbol: '&#163;' },
    { code: 'EUR', currency: 'EUR', icon: 'icon-euro', is_symbol: false, symbol: '&#8364;' },
    { code: 'AE', currency: 'AED', icon: 'AED', is_symbol: false, symbol: 'AED' },
    { code: 'SA', currency: 'SAR', icon: 'SAR', is_symbol: false, symbol: 'SAR' },
    { code: 'SG', currency: 'SGD', icon: 'SGD', is_symbol: false, symbol: 'SGD' },
    { code: 'CA', currency: 'CAD', icon: 'CAD', is_symbol: false, symbol: 'CAD' },
    { code: 'HK', currency: 'HKD', icon: 'HKD', is_symbol: false, symbol: 'HKD' },
    { code: 'QA', currency: 'QAR', icon: 'QAR', is_symbol: false, symbol: 'QAR' },
    { code: 'AU', currency: 'AUD', icon: 'AUD', is_symbol: false, symbol: 'AUD' },
    { code: 'MY', currency: 'MYR', icon: 'MYR', is_symbol: false, symbol: 'MYR' }
];

export const EuropeanCountries = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'];
export const GulfCountries = ['BH', 'IR', 'IQ', 'KW', 'OM', 'QA', 'SA', 'YE'];
