import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const Services: any[] = [];

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        // ...Services,
      ]
    };
  }
}
