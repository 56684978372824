import { NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ComponentLoader } from 'src/app/core/component-loader/component-loader.component';
import { IUser } from 'src/app/shared/model/user.model';
import { EventsService, UtilService, VariablesService } from 'src/app/shared/services';
import { SideNavComponent } from '../side-nav/side-nav.component';

@Component({
  selector: 'app-header-v2',
  standalone: true,
  imports: [
    NgIf, NgTemplateOutlet, NgOptimizedImage, RouterModule, MatIconModule, MatRippleModule, ComponentLoader,
    SideNavComponent
  ],
  templateUrl: './header-v2.component.html',
  styleUrls: ['./header-v2.component.scss']
})
export class HeaderV2Component {
  userData?: IUser;

  constructor(
    private events: EventsService,
    public vars: VariablesService,
    private util: UtilService,
    private router: Router
  ) {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.getUserData();
        }
    })
  }

  ngOnInit(){
    this.getUserData();
  }

  getUserData() {
    this.vars.userData$.subscribe((val: (IUser | undefined)) => {
      this.userData = val;
    })
  }

  openLogin(form: any) {
    const dialogRef = this.util.dialog.open(form, {
      width: '100%',
      maxWidth: '741px',
      panelClass: 'signin-form'
    });

    this.events.sendSystemEvent({
      eventName: 'signin clicked',
      event_type: 'signin'
    });
  }

  onProfileSidebarOpen() {
    this.vars.sidebarDrawerState.next(true);
    if (this.vars.isBrowser) {
      document.body.style.overflowY = 'hidden';
    }
  }

  // goToAllFundraiser() {
  //   this.events.sendSystemEvent({
  //     eventName: 'click_on_contribute',
  //     info_1: 'header'
  //   });
  //   const abandonedCart = this.storage.get('abandonedCartOtd');
  //   if (abandonedCart?.link) {
  //     this.util.router.navigate([abandonedCart?.link], { queryParams: { donate: 1, payment: 'form' }, queryParamsHandling: 'merge'});
  //   } else {
  //     this.util.navigate('fundraisers');
  //   }
  // }
}
