import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService, UtilService, VariablesService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public router: Router,
    public storageService: StorageService,
    private util: UtilService,
    private vars: VariablesService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin(state);
  }
  checkLogin(state: RouterStateSnapshot) {
    const curUrl = state.url.split('?')[0];
    const user = this.util.getUser();
    const isPermLogin = this.vars.isPermanentLoggedIn || user?.login;
    if (isPermLogin && curUrl.match('signin')) {
      this.router.navigate([state.root.queryParams?.['redirect_uri'] || '/'], { queryParams: { ...state.root.queryParams, redirect_uri: null } });
      return false;
    }
    if (isPermLogin || curUrl.match('signin')) {
      return true;
    }

    const qPrams = Object.assign({ redirect_uri: state.url.split('?')[0] }, state.root.queryParams);
    this.router.navigate(['/signin'], { queryParams: qPrams });
    return false;
  }
}
