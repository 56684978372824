import { Injectable } from '@angular/core';

declare let slab_AB: any;
declare let story_AB: any;

@Injectable({
  providedIn: 'root'
})
export class ABService {

  constructor() { }

  slab_AB() {
    if (typeof slab_AB !== 'undefined') {
      return slab_AB;
    }
  }

  story_AB() {
    if (typeof story_AB !== 'undefined') {
      return story_AB;
    }
  }
}
