export interface ICountry {
  name: string;
  nameInLocalLang: string;
  code: string;
  dialCode: string;
  flag: string;
  pattern: RegExp;
}

export const PriorCountries = ['us', 'ae', 'gb', 'in'];

export const COUNTRIES:ICountry[] = [
{name:'Afghanistan',nameInLocalLang:'افغانستان',code:'af',dialCode:'+93',flag:'https://flagcdn.com/w320/af.png',pattern:/^\d{9}$/},
{name:'Albania',nameInLocalLang:'Shqipëri',code:'al',dialCode:'+355',flag:'https://flagcdn.com/w320/al.png',pattern:/^\d{9}$/},
{name:'Algeria',nameInLocalLang:'الجزائر',code:'dz',dialCode:'+213',flag:'https://flagcdn.com/w320/dz.png',pattern:/^\d{9}$/},
{name:'Andorra',nameInLocalLang:'',code:'ad',dialCode:'+376',flag:'https://flagcdn.com/w320/ad.png',pattern:/^\d{6}$/},
{name:'Angola',nameInLocalLang:'Angola',code:'ao',dialCode:'+244',flag:'https://flagcdn.com/w320/ao.png',pattern:/^\d{9}$/},
{name:'Antigua and Barbuda',nameInLocalLang:'',code:'ag',dialCode:'+1-268',flag:'https://flagcdn.com/w320/ag.png',pattern:/^\d{9}$/},
{name:'Argentina',nameInLocalLang:'Argentina',code:'ar',dialCode:'+54',flag:'https://flagcdn.com/w320/ar.png',pattern:/^\d{10,11}$/},
{name:'Armenia',nameInLocalLang:'Հայաստան',code:'am',dialCode:'+374',flag:'https://flagcdn.com/w320/am.png',pattern:/^\d{8}$/},
{name:'Australia',nameInLocalLang:'',code:'au',dialCode:'+61',flag:'https://flagcdn.com/w320/au.png',pattern:/^\d{9}$/},
{name:'Austria',nameInLocalLang:'Österreich',code:'at',dialCode:'+43',flag:'https://flagcdn.com/w320/at.png',pattern:/^\d{10}$/},
{name:'Azerbaijan',nameInLocalLang:'Azərbaycan',code:'az',dialCode:'+994',flag:'https://flagcdn.com/w320/az.png',pattern:/^\d{9}$/},
{name:'Bahamas',nameInLocalLang:'',code:'bs',dialCode:'+1-242',flag:'https://flagcdn.com/w320/bs.png',pattern:/^\d{9}$/},
{name:'Bahrain',nameInLocalLang:'البحرين',code:'bh',dialCode:'+973',flag:'https://flagcdn.com/w320/bh.png',pattern:/^\d{8}$/},
{name:'Bangladesh',nameInLocalLang:'বাংলাদেশ',code:'bd',dialCode:'+880',flag:'https://flagcdn.com/w320/bd.png',pattern:/^\d{10}$/},
{name:'Barbados',nameInLocalLang:'',code:'bb',dialCode:'+1-246',flag:'https://flagcdn.com/w320/bb.png',pattern:/^\d{9}$/},
{name:'Belarus',nameInLocalLang:'Беларусь',code:'by',dialCode:'+375',flag:'https://flagcdn.com/w320/by.png',pattern:/^\d{9}$/},
{name:'Belgium',nameInLocalLang:'België',code:'be',dialCode:'+32',flag:'https://flagcdn.com/w320/be.png',pattern:/^\d{9}$/},
{name:'Belize',nameInLocalLang:'',code:'bz',dialCode:'+501',flag:'https://flagcdn.com/w320/bz.png',pattern:/^\d{7}$/},
{name:'Benin',nameInLocalLang:'Bénin',code:'bj',dialCode:'+229',flag:'https://flagcdn.com/w320/bj.png',pattern:/^\d{8}$/},
{name:'Bhutan',nameInLocalLang:'འབྲུག',code:'bt',dialCode:'+975',flag:'https://flagcdn.com/w320/bt.png',pattern:/^\d{8}$/},
{name:'Bolivia',nameInLocalLang:'Bolivia',code:'bo',dialCode:'+591',flag:'https://flagcdn.com/w320/bo.png',pattern:/^\d{8}$/},
{name:'Bosnia and Herzegovina',nameInLocalLang:'Bosna i Hercegovina',code:'ba',dialCode:'+387',flag:'https://flagcdn.com/w320/ba.png',pattern:/^\d{8,9}$/},
{name:'Botswana',nameInLocalLang:'Botswana',code:'bw',dialCode:'+267',flag:'https://flagcdn.com/w320/bw.png',pattern:/^\d{8}$/},
{name:'Brazil',nameInLocalLang:'Brasil',code:'br',dialCode:'+55',flag:'https://flagcdn.com/w320/br.png',pattern:/^\d{10,11}$/},
{name:'Brunei',nameInLocalLang:'Brunei',code:'bn',dialCode:'+673',flag:'https://flagcdn.com/w320/bn.png',pattern:/^\d{7}$/},
{name:'Bulgaria',nameInLocalLang:'България',code:'bg',dialCode:'+359',flag:'https://flagcdn.com/w320/bg.png',pattern:/^\d{8,9}$/},
{name:'Burkina Faso',nameInLocalLang:'Burkina Faso',code:'bf',dialCode:'+226',flag:'https://flagcdn.com/w320/bf.png',pattern:/^\d{8}$/},
{name:'Burundi',nameInLocalLang:'Burundi',code:'bi',dialCode:'+257',flag:'https://flagcdn.com/w320/bi.png',pattern:/^\d{8}$/},
{name:'Cabo Verde',nameInLocalLang:'Kabu Verdi',code:'cv',dialCode:'+238',flag:'https://flagcdn.com/w320/cv.png',pattern:/^\d{7}$/},
{name:'Cambodia',nameInLocalLang:'កម្ពុជា',code:'kh',dialCode:'+855',flag:'https://flagcdn.com/w320/kh.png',pattern:/^\d{9}$/},
{name:'Cameroon',nameInLocalLang:'Cameroun',code:'cm',dialCode:'+237',flag:'https://flagcdn.com/w320/cm.png',pattern:/^\d{9}$/},
{name:'Canada',nameInLocalLang:'',code:'ca',dialCode:'+1',flag:'https://flagcdn.com/w320/ca.png',pattern:/^[2-9]\d{2}[2-9](?!11)\d{6}$/},
{name:'Central African Republic',nameInLocalLang:'République centrafricaine',code:'cf',dialCode:'+236',flag:'https://flagcdn.com/w320/cf.png',pattern:/^\d{8}$/},
{name:'Chad',nameInLocalLang:'Tchad',code:'td',dialCode:'+235',flag:'https://flagcdn.com/w320/td.png',pattern:/^\d{8}$/},
{name:'Chile',nameInLocalLang:'Chile',code:'cl',dialCode:'+56',flag:'https://flagcdn.com/w320/cl.png',pattern:/^\d{9}$/},
{name:'China',nameInLocalLang:'中国',code:'cn',dialCode:'+86',flag:'https://flagcdn.com/w320/cn.png',pattern:/^\d{11}$/},
{name:'Colombia',nameInLocalLang:'Colombia',code:'co',dialCode:'+57',flag:'https://flagcdn.com/w320/co.png',pattern:/^\d{10}$/},
{name:'Comoros',nameInLocalLang:'القمُر',code:'km',dialCode:'+269',flag:'https://flagcdn.com/w320/km.png',pattern:/^\d{7}$/},
{name:'Congo',nameInLocalLang:'Congo',code:'cg',dialCode:'+242',flag:'https://flagcdn.com/w320/cg.png',pattern:/^\d{9}$/},
{name:'Congo,Democratic epublic of the',nameInLocalLang:'République Démocratique du Congo',code:'cd',dialCode:'+243',flag:'https://flagcdn.com/w320/cd.png',pattern:/^\d{9}$/},
{name:'Costa Rica',nameInLocalLang:'Costa Rica',code:'cr',dialCode:'+506',flag:'https://flagcdn.com/w320/cr.png',pattern:/^\d{8}$/},
{name:'Croatia',nameInLocalLang:'Hrvatska',code:'hr',dialCode:'+385',flag:'https://flagcdn.com/w320/hr.png',pattern:/^\d{9}$/},
{name:'Cuba',nameInLocalLang:'Cuba',code:'cu',dialCode:'+53',flag:'https://flagcdn.com/w320/cu.png',pattern:/^\d{8}$/},
{name:'Cyprus',nameInLocalLang:'Κύπρος',code:'cy',dialCode:'+357',flag:'https://flagcdn.com/w320/cy.png',pattern:/^\d{8}$/},
{name:'Czechia',nameInLocalLang:'Česká republika',code:'cz',dialCode:'+420',flag:'https://flagcdn.com/w320/cz.png',pattern:/^\d{9}$/},
{name:'Denmark',nameInLocalLang:'Danmark',code:'dk',dialCode:'+45',flag:'https://flagcdn.com/w320/dk.png',pattern:/^\d{8}$/},
{name:'Djibouti',nameInLocalLang:'Djibouti',code:'dj',dialCode:'+253',flag:'https://flagcdn.com/w320/dj.png',pattern:/^\d{6}$/},
{name:'Dominica',nameInLocalLang:'',code:'dm',dialCode:'+1-767',flag:'https://flagcdn.com/w320/dm.png',pattern:/^\d{7}$/},
{name:'Dominican Republic',nameInLocalLang:'República Dominicana',code:'do',dialCode:'+1-809',flag:'https://flagcdn.com/w320/do.png',pattern:/^\d{7}$/},
{name:'Ecuador',nameInLocalLang:'Ecuador',code:'ec',dialCode:'+593',flag:'https://flagcdn.com/w320/ec.png',pattern:/^\d{9}$/},
{name:'Egypt',nameInLocalLang:'مصر',code:'eg',dialCode:'+20',flag:'https://flagcdn.com/w320/eg.png',pattern:/^\d{10}$/},
{name:'El Salvador',nameInLocalLang:'El Salvador',code:'sv',dialCode:'+503',flag:'https://flagcdn.com/w320/sv.png',pattern:/^\d{8}$/},
{name:'Equatorial Guinea',nameInLocalLang:'Guinea Ecuatorial',code:'gq',dialCode:'+240',flag:'https://flagcdn.com/w320/gq.png',pattern:/^\d{9}$/},
{name:'Eritrea',nameInLocalLang:'ኤርትራ',code:'er',dialCode:'+291',flag:'https://flagcdn.com/w320/er.png',pattern:/^\d{7}$/},
{name:'Estonia',nameInLocalLang:'Eesti',code:'ee',dialCode:'+372',flag:'https://flagcdn.com/w320/ee.png',pattern:/^\d{7,8}$/},
{name:'Eswatini',nameInLocalLang:'eSwatini',code:'sz',dialCode:'+268',flag:'https://flagcdn.com/w320/sz.png',pattern:/^\d{8}$/},
{name:'Ethiopia',nameInLocalLang:'ኢትዮጵያ',code:'et',dialCode:'+251',flag:'https://flagcdn.com/w320/et.png',pattern:/^\d{9}$/},
{name:'Fiji',nameInLocalLang:'Fiji',code:'fj',dialCode:'+679',flag:'https://flagcdn.com/w320/fj.png',pattern:/^\d{7}$/},
{name:'Finland',nameInLocalLang:'Suomi',code:'fi',dialCode:'+358',flag:'https://flagcdn.com/w320/fi.png',pattern:/^\d{9}$/},
{name:'France',nameInLocalLang:'France',code:'fr',dialCode:'+33',flag:'https://flagcdn.com/w320/fr.png',pattern:/^\d{9}$/},
{name:'Gabon',nameInLocalLang:'Gabon',code:'ga',dialCode:'+241',flag:'https://flagcdn.com/w320/ga.png',pattern:/^\d{7}$/},
{name:'Gambia',nameInLocalLang:'Gambia',code:'gm',dialCode:'+220',flag:'https://flagcdn.com/w320/gm.png',pattern:/^\d{7}$/},
{name:'Georgia',nameInLocalLang:'საქართველო',code:'ge',dialCode:'+995',flag:'https://flagcdn.com/w320/ge.png',pattern:/^\d{9}$/},
{name:'Germany',nameInLocalLang:'Deutschland',code:'de',dialCode:'+49',flag:'https://flagcdn.com/w320/de.png',pattern:/^\d{10,11}$/},
{name:'Ghana',nameInLocalLang:'Ghana',code:'gh',dialCode:'+233',flag:'https://flagcdn.com/w320/gh.png',pattern:/^\d{9}$/},
{name:'Greece',nameInLocalLang:'Ελλάδα',code:'gr',dialCode:'+30',flag:'https://flagcdn.com/w320/gr.png',pattern:/^\d{10}$/},
{name:'Grenada',nameInLocalLang:'',code:'gd',dialCode:'+1-473',flag:'https://flagcdn.com/w320/gd.png',pattern:/^\d{7}$/},
{name:'Guatemala',nameInLocalLang:'Guatemala',code:'gt',dialCode:'+502',flag:'https://flagcdn.com/w320/gt.png',pattern:/^\d{8}$/},
{name:'Guinea',nameInLocalLang:'Guinée',code:'gn',dialCode:'+224',flag:'https://flagcdn.com/w320/gn.png',pattern:/^\d{9}$/},
{name:'Guinea-Bissau',nameInLocalLang:'Guiné-Bissau',code:'gw',dialCode:'+245',flag:'https://flagcdn.com/w320/gw.png',pattern:/^\d{7}$/},
{name:'Guyana',nameInLocalLang:'Guyana',code:'gy',dialCode:'+592',flag:'https://flagcdn.com/w320/gy.png',pattern:/^\d{7}$/},
{name:'Haiti',nameInLocalLang:'Haïti',code:'ht',dialCode:'+509',flag:'https://flagcdn.com/w320/ht.png',pattern:/^\d{8}$/},
{name:'Honduras',nameInLocalLang:'Honduras',code:'hn',dialCode:'+504',flag:'https://flagcdn.com/w320/hn.png',pattern:/^\d{8}$/},
{name:'Hungary',nameInLocalLang:'Magyarország',code:'hu',dialCode:'+36',flag:'https://flagcdn.com/w320/hu.png',pattern:/^\d{9}$/},
{name:'Iceland',nameInLocalLang:'Ísland',code:'is',dialCode:'+354',flag:'https://flagcdn.com/w320/is.png',pattern:/^\d{7}$/},
{name:'India',nameInLocalLang:'भारत',code:'in',dialCode:'+91',flag:'https://flagcdn.com/w320/in.png',pattern:/^\d{10}$/},
{name:'Indonesia',nameInLocalLang:'Indonesia',code:'id',dialCode:'+62',flag:'https://flagcdn.com/w320/id.png',pattern:/^\d{10,13}$/},
{name:'Iran',nameInLocalLang:'ایران',code:'ir',dialCode:'+98',flag:'https://flagcdn.com/w320/ir.png',pattern:/^\d{11}$/},
{name:'Iraq',nameInLocalLang:'العراق',code:'iq',dialCode:'+964',flag:'https://flagcdn.com/w320/iq.png',pattern:/^\d{10}$/},
{name:'Ireland',nameInLocalLang:'Éire',code:'ie',dialCode:'+353',flag:'https://flagcdn.com/w320/ie.png',pattern:/^\d{7,10}$/},
{name:'Israel',nameInLocalLang:'ישראל',code:'il',dialCode:'+972',flag:'https://flagcdn.com/w320/il.png',pattern:/^\d{9}$/},
{name:'Italy',nameInLocalLang:'Italia',code:'it',dialCode:'+39',flag:'https://flagcdn.com/w320/it.png',pattern:/^\d{10}$/},
{name:'Jamaica',nameInLocalLang:'',code:'jm',dialCode:'+1-876',flag:'https://flagcdn.com/w320/jm.png',pattern:/^\d{7}$/},
{name:'Japan',nameInLocalLang:'日本',code:'jp',dialCode:'+81',flag:'https://flagcdn.com/w320/jp.png',pattern:/^\d{10,11}$/},
{name:'Jordan',nameInLocalLang:'الأردن',code:'jo',dialCode:'+962',flag:'https://flagcdn.com/w320/jo.png',pattern:/^\d{9}$/},
{name:'Kazakhstan',nameInLocalLang:'Қазақстан',code:'kz',dialCode:'+7',flag:'https://flagcdn.com/w320/kz.png',pattern:/^\d{10}$/},
{name:'Kenya',nameInLocalLang:'Kenya',code:'ke',dialCode:'+254',flag:'https://flagcdn.com/w320/ke.png',pattern:/^\d{10}$/},
{name:'Kiribati',nameInLocalLang:'Kiribati',code:'ki',dialCode:'+686',flag:'https://flagcdn.com/w320/ki.png',pattern:/^\d{7}$/},
{name:'Korea,North',nameInLocalLang:'조선',code:'kp',dialCode:'+850',flag:'https://flagcdn.com/w320/kp.png',pattern:/^\d{8}$/},
{name:'Korea,South',nameInLocalLang:'대한민국',code:'kr',dialCode:'+82',flag:'https://flagcdn.com/w320/kr.png',pattern:/^\d{10,11}$/},
{name:'Kosovo',nameInLocalLang:'Kosovë',code:'xk',dialCode:'+383',flag:'https://flagcdn.com/w320/xk.png',pattern:/^\d{8}$/},
{name:'Kuwait',nameInLocalLang:'الكويت',code:'kw',dialCode:'+965',flag:'https://flagcdn.com/w320/kw.png',pattern:/^\d{8}$/},
{name:'Kyrgyzstan',nameInLocalLang:'Кыргызстан',code:'kg',dialCode:'+996',flag:'https://flagcdn.com/w320/kg.png',pattern:/^\d{9}$/},
{name:'Laos',nameInLocalLang:'ລາວ',code:'la',dialCode:'+856',flag:'https://flagcdn.com/w320/la.png',pattern:/^\d{8}$/},
{name:'Latvia',nameInLocalLang:'Latvija',code:'lv',dialCode:'+371',flag:'https://flagcdn.com/w320/lv.png',pattern:/^\d{8}$/},
{name:'Lebanon',nameInLocalLang:'لبنان',code:'lb',dialCode:'+961',flag:'https://flagcdn.com/w320/lb.png',pattern:/^\d{8}$/},
{name:'Lesotho',nameInLocalLang:'Lesotho',code:'ls',dialCode:'+266',flag:'https://flagcdn.com/w320/ls.png',pattern:/^\d{8}$/},
{name:'Liberia',nameInLocalLang:'Liberia',code:'lr',dialCode:'+231',flag:'https://flagcdn.com/w320/lr.png',pattern:/^\d{7}$/},
{name:'Libya',nameInLocalLang:'ليبيا',code:'ly',dialCode:'+218',flag:'https://flagcdn.com/w320/ly.png',pattern:/^\d{9}$/},
{name:'Liechtenstein',nameInLocalLang:'Liechtenstein',code:'li',dialCode:'+423',flag:'https://flagcdn.com/w320/li.png',pattern:/^\d{8}$/},
{name:'Lithuania',nameInLocalLang:'Lietuva',code:'lt',dialCode:'+370',flag:'https://flagcdn.com/w320/lt.png',pattern:/^\d{8,9}$/},
{name:'Luxembourg',nameInLocalLang:'Luxembourg',code:'lu',dialCode:'+352',flag:'https://flagcdn.com/w320/lu.png',pattern:/^\d{6,8}$/},
{name:'Madagascar',nameInLocalLang:'Madagasikara',code:'mg',dialCode:'+261',flag:'https://flagcdn.com/w320/mg.png',pattern:/^\d{9}$/},
{name:'Malawi',nameInLocalLang:'Malawi',code:'mw',dialCode:'+265',flag:'https://flagcdn.com/w320/mw.png',pattern:/^\d{9}$/},
{name:'Malaysia',nameInLocalLang:'Malaysia',code:'my',dialCode:'+60',flag:'https://flagcdn.com/w320/my.png',pattern:/^\d{10,11}$/},
{name:'Maldives',nameInLocalLang:'ދިވެހި',code:'mv',dialCode:'+960',flag:'https://flagcdn.com/w320/mv.png',pattern:/^\d{7}$/},
{name:'Mali',nameInLocalLang:'Mali',code:'ml',dialCode:'+223',flag:'https://flagcdn.com/w320/ml.png',pattern:/^\d{8}$/},
{name:'Malta',nameInLocalLang:'Malta',code:'mt',dialCode:'+356',flag:'https://flagcdn.com/w320/mt.png',pattern:/^\d{8}$/},
{name:'Marshall Islands',nameInLocalLang:'',code:'mh',dialCode:'+692',flag:'https://flagcdn.com/w320/mh.png',pattern:/^\d{7}$/},
{name:'Mauritania',nameInLocalLang:'المرس',code:'mr',dialCode:'+222',flag:'https://flagcdn.com/w320/mr.png',pattern:/^\d{8}$/},
{name:'Mauritius',nameInLocalLang:'Maurice',code:'mu',dialCode:'+230',flag:'https://flagcdn.com/w320/mu.png',pattern:/^\d{8}$/},
{name:'Mexico',nameInLocalLang:'México',code:'mx',dialCode:'+52',flag:'https://flagcdn.com/w320/mx.png',pattern:/^\d{10}$/},
{name:'Micronesia',nameInLocalLang:'Federated States of Micronesia',code:'fm',dialCode:'+691',flag:'https://flagcdn.com/w320/fm.png',pattern:/^\d{7}$/},
{name:'Moldova',nameInLocalLang:'Moldova',code:'md',dialCode:'+373',flag:'https://flagcdn.com/w320/md.png',pattern:/^\d{8}$/},
{name:'Monaco',nameInLocalLang:'Monaco',code:'mc',dialCode:'+377',flag:'https://flagcdn.com/w320/mc.png',pattern:/^\d{8}$/},
{name:'Mongolia',nameInLocalLang:'Монгол Улс',code:'mn',dialCode:'+976',flag:'https://flagcdn.com/w320/mn.png',pattern:/^\d{8}$/},
{name:'Montenegro',nameInLocalLang:'Crna Gora',code:'me',dialCode:'+382',flag:'https://flagcdn.com/w320/me.png',pattern:/^\d{8}$/},
{name:'Morocco',nameInLocalLang:'المغرب',code:'ma',dialCode:'+212',flag:'https://flagcdn.com/w320/ma.png',pattern:/^\d{10}$/},
{name:'Mozambique',nameInLocalLang:'Moçambique',code:'mz',dialCode:'+258',flag:'https://flagcdn.com/w320/mz.png',pattern:/^\d{9}$/},
{name:'Myanmar',nameInLocalLang:'မြန်မာ',code:'mm',dialCode:'+95',flag:'https://flagcdn.com/w320/mm.png',pattern:/^\d{7,9}$/},
{name:'Namibia',nameInLocalLang:'Namibia',code:'na',dialCode:'+264',flag:'https://flagcdn.com/w320/na.png',pattern:/^\d{7,9}$/},
{name:'Nauru',nameInLocalLang:'Nauru',code:'nr',dialCode:'+674',flag:'https://flagcdn.com/w320/nr.png',pattern:/^\d{7}$/},
{name:'Nepal',nameInLocalLang:'नेपाल',code:'np',dialCode:'+977',flag:'https://flagcdn.com/w320/np.png',pattern:/^\d{10}$/},
{name:'Netherlands',nameInLocalLang:'Nederland',code:'nl',dialCode:'+31',flag:'https://flagcdn.com/w320/nl.png',pattern:/^\d{9}$/},
{name:'New Zealand',nameInLocalLang:'New Zealand',code:'nz',dialCode:'+64',flag:'https://flagcdn.com/w320/nz.png',pattern:/^\d{9}$/},
{name:'Nicaragua',nameInLocalLang:'Nicaragua',code:'ni',dialCode:'+505',flag:'https://flagcdn.com/w320/ni.png',pattern:/^\d{8}$/},
{name:'Niger',nameInLocalLang:'Niger',code:'ne',dialCode:'+227',flag:'https://flagcdn.com/w320/ne.png',pattern:/^\d{8}$/},
{name:'Nigeria',nameInLocalLang:'Nigeria',code:'ng',dialCode:'+234',flag:'https://flagcdn.com/w320/ng.png',pattern:/^\d{10}$/},
{name:'North Macedonia',nameInLocalLang:'Северна Македонија',code:'mk',dialCode:'+389',flag:'https://flagcdn.com/w320/mk.png',pattern:/^\d{8}$/},
{name:'Norway',nameInLocalLang:'Norge',code:'no',dialCode:'+47',flag:'https://flagcdn.com/w320/no.png',pattern:/^\d{8}$/},
{name:'Oman',nameInLocalLang:'عمان',code:'om',dialCode:'+968',flag:'https://flagcdn.com/w320/om.png',pattern:/^\d{8}$/},
{name:'Pakistan',nameInLocalLang:'پاکستان',code:'pk',dialCode:'+92',flag:'https://flagcdn.com/w320/pk.png',pattern:/^\d{10}$/},
{name:'Palau',nameInLocalLang:'Palau',code:'pw',dialCode:'+680',flag:'https://flagcdn.com/w320/pw.png',pattern:/^\d{7}$/},
{name:'Palestine',nameInLocalLang:'فلسطين',code:'ps',dialCode:'+970',flag:'https://flagcdn.com/w320/ps.png',pattern:/^\d{9}$/},
{name:'Panama',nameInLocalLang:'Panamá',code:'pa',dialCode:'+507',flag:'https://flagcdn.com/w320/pa.png',pattern:/^\d{8}$/},
{name:'Papua New Guinea',nameInLocalLang:'Papua New Guinea',code:'pg',dialCode:'+675',flag:'https://flagcdn.com/w320/pg.png',pattern:/^\d{10}$/},
{name:'Paraguay',nameInLocalLang:'Paraguay',code:'py',dialCode:'+595',flag:'https://flagcdn.com/w320/py.png',pattern:/^\d{9}$/},
{name:'Peru',nameInLocalLang:'Perú',code:'pe',dialCode:'+51',flag:'https://flagcdn.com/w320/pe.png',pattern:/^\d{9}$/},
{name:'Philippines',nameInLocalLang:'Pilipinas',code:'ph',dialCode:'+63',flag:'https://flagcdn.com/w320/ph.png',pattern:/^\d{10}$/},
{name:'Poland',nameInLocalLang:'Polska',code:'pl',dialCode:'+48',flag:'https://flagcdn.com/w320/pl.png',pattern:/^\d{9}$/},
{name:'Portugal',nameInLocalLang:'Portugal',code:'pt',dialCode:'+351',flag:'https://flagcdn.com/w320/pt.png',pattern:/^\d{9}$/},
{name:'Qatar',nameInLocalLang:'قطر',code:'qa',dialCode:'+974',flag:'https://flagcdn.com/w320/qa.png',pattern:/^\d{8}$/},
{name:'Romania',nameInLocalLang:'România',code:'ro',dialCode:'+40',flag:'https://flagcdn.com/w320/ro.png',pattern:/^\d{10}$/},
{name:'Russia',nameInLocalLang:'Россия',code:'ru',dialCode:'+7',flag:'https://flagcdn.com/w320/ru.png',pattern:/^\d{9}$/},
{name:'Rwanda',nameInLocalLang:'Rwanda',code:'rw',dialCode:'+250',flag:'https://flagcdn.com/w320/rw.png',pattern:/^\d{6}$/},
{name:'Saint Kitts and Nevis',nameInLocalLang:'',code:'kn',dialCode:'+1-869',flag:'https://flagcdn.com/w320/kn.png',pattern:/^\d{6}$/},
{name:'Saint Lucia',nameInLocalLang:'',code:'lc',dialCode:'+1-758',flag:'https://flagcdn.com/w320/lc.png',pattern:/^\d{6}$/},
{name:'Saint Vincent and the Grenadines',nameInLocalLang:'',code:'vc',dialCode:'+1-784',flag:'https://flagcdn.com/w320/vc.png',pattern:/^\d{7}$/},
{name:'Samoa',nameInLocalLang:'Sāmoa',code:'ws',dialCode:'+685',flag:'https://flagcdn.com/w320/ws.png',pattern:/^\d{6,7}$/},
{name:'San Marino',nameInLocalLang:'San Marino',code:'sm',dialCode:'+378',flag:'https://flagcdn.com/w320/sm.png',pattern:/^\d{6,7}$/},
{name:'Sao Tome and Principe',nameInLocalLang:'São Tomé e Príncipe',code:'st',dialCode:'+239',flag:'https://flagcdn.com/w320/st.png',pattern:/^\d{6,7}$/},
{name:'Saudi Arabia',nameInLocalLang:'المملكة العربية السعودية',code:'sa',dialCode:'+966',flag:'https://flagcdn.com/w320/sa.png',pattern:/^\d{9}$/},
{name:'Senegal',nameInLocalLang:'Sénégal',code:'sn',dialCode:'+221',flag:'https://flagcdn.com/w320/sn.png',pattern:/^\d{9}$/},
{name:'Serbia',nameInLocalLang:'Србија',code:'rs',dialCode:'+381',flag:'https://flagcdn.com/w320/rs.png',pattern:/^\d{9,10}$/},
{name:'Seychelles',nameInLocalLang:'Seychelles',code:'sc',dialCode:'+248',flag:'https://flagcdn.com/w320/sc.png',pattern:/^\d{7}$/},
{name:'Sierra Leone',nameInLocalLang:'Sierra Leone',code:'sl',dialCode:'+232',flag:'https://flagcdn.com/w320/sl.png',pattern:/^\d{8}$/},
{name:'Singapore',nameInLocalLang:'Singapore',code:'sg',dialCode:'+65',flag:'https://flagcdn.com/w320/sg.png',pattern:/^\d{8}$/},
{name:'Slovakia',nameInLocalLang:'Slovensko',code:'sk',dialCode:'+421',flag:'https://flagcdn.com/w320/sk.png',pattern:/^\d{9}$/},
{name:'Slovenia',nameInLocalLang:'Slovenija',code:'si',dialCode:'+386',flag:'https://flagcdn.com/w320/si.png',pattern:/^\d{8}$/},
{name:'Solomon Islands',nameInLocalLang:'Solomon Islands',code:'sb',dialCode:'+677',flag:'https://flagcdn.com/w320/sb.png',pattern:/^\d{7}$/},
{name:'Somalia',nameInLocalLang:'Soomaaliya',code:'so',dialCode:'+252',flag:'https://flagcdn.com/w320/so.png',pattern:/^\d{7,9}$/},
{name:'South Africa',nameInLocalLang:'South Africa',code:'za',dialCode:'+27',flag:'https://flagcdn.com/w320/za.png',pattern:/^\d{10}$/},
{name:'South Sudan',nameInLocalLang:'جنوب السودان',code:'ss',dialCode:'+211',flag:'https://flagcdn.com/w320/ss.png',pattern:/^\d{9}$/},
{name:'Spain',nameInLocalLang:'España',code:'es',dialCode:'+34',flag:'https://flagcdn.com/w320/es.png',pattern:/^\d{9}$/},
{name:'Sri Lanka',nameInLocalLang:'ශ්‍රී ලංකාව',code:'lk',dialCode:'+94',flag:'https://flagcdn.com/w320/lk.png',pattern:/^\d{10}$/},
{name:'Sudan',nameInLocalLang:'السودان',code:'sd',dialCode:'+249',flag:'https://flagcdn.com/w320/sd.png',pattern:/^\d{10}$/},
{name:'Suriname',nameInLocalLang:'Suriname',code:'sr',dialCode:'+597',flag:'https://flagcdn.com/w320/sr.png',pattern:/^\d{7}$/},
{name:'Sweden',nameInLocalLang:'Sverige',code:'se',dialCode:'+46',flag:'https://flagcdn.com/w320/se.png',pattern:/^\d{10}$/},
{name:'Switzerland',nameInLocalLang:'Schweiz',code:'ch',dialCode:'+41',flag:'https://flagcdn.com/w320/ch.png',pattern:/^\d{9}$/},
{name:'Syria',nameInLocalLang:'سوريا',code:'sy',dialCode:'+963',flag:'https://flagcdn.com/w320/sy.png',pattern:/^\d{9}$/},
{name:'Taiwan',nameInLocalLang:'台灣',code:'tw',dialCode:'+886',flag:'https://flagcdn.com/w320/tw.png',pattern:/^\d{10}$/},
{name:'Tajikistan',nameInLocalLang:'Тоҷикистон',code:'tj',dialCode:'+992',flag:'https://flagcdn.com/w320/tj.png',pattern:/^\d{9}$/},
{name:'Tanzania',nameInLocalLang:'Tanzania',code:'tz',dialCode:'+255',flag:'https://flagcdn.com/w320/tz.png',pattern:/^\d{10}$/},
{name:'Thailand',nameInLocalLang:'ไทย',code:'th',dialCode:'+66',flag:'https://flagcdn.com/w320/th.png',pattern:/^\d{9,10}$/},
{name:'Timor-Leste',nameInLocalLang:'Timor-Leste',code:'tl',dialCode:'+670',flag:'https://flagcdn.com/w320/tl.png',pattern:/^\d{9}$/},
{name:'Togo',nameInLocalLang:'Togo',code:'tg',dialCode:'+228',flag:'https://flagcdn.com/w320/tg.png',pattern:/^\d{8}$/},
{name:'Tonga',nameInLocalLang:'Tonga',code:'to',dialCode:'+676',flag:'https://flagcdn.com/w320/to.png',pattern:/^\d{4,5}$/},
{name:'Trinidad and Tobago',nameInLocalLang:'',code:'tt',dialCode:'+1-868',flag:'https://flagcdn.com/w320/tt.png',pattern:/^\d{6}$/},
{name:'Tunisia',nameInLocalLang:'تونس',code:'tn',dialCode:'+216',flag:'https://flagcdn.com/w320/tn.png',pattern:/^\d{8}$/},
{name:'Turkey',nameInLocalLang:'Türkiye',code:'tr',dialCode:'+90',flag:'https://flagcdn.com/w320/tr.png',pattern:/^\d{10}$/},
{name:'Turkmenistan',nameInLocalLang:'Türkmenistan',code:'tm',dialCode:'+993',flag:'https://flagcdn.com/w320/tm.png',pattern:/^\d{8}$/},
{name:'Tuvalu',nameInLocalLang:'Tuvalu',code:'tv',dialCode:'+688',flag:'https://flagcdn.com/w320/tv.png',pattern:/^\d{7}$/},
{name:'Uganda',nameInLocalLang:'Uganda',code:'ug',dialCode:'+256',flag:'https://flagcdn.com/w320/ug.png',pattern:/^\d{9}$/},
{name:'Ukraine',nameInLocalLang:'Україна',code:'ua',dialCode:'+380',flag:'https://flagcdn.com/w320/ua.png',pattern:/^\d{9,10}$/},
{name:'United Arab Emirates',nameInLocalLang:'الإمارات العربية المتحدة',code:'ae',dialCode:'+971',flag:'https://flagcdn.com/w320/ae.png',pattern:/^\d{9}$/},
{name:'United Kingdom',nameInLocalLang:'United Kingdom',code:'gb',dialCode:'+44',flag:'https://flagcdn.com/w320/gb.png',pattern:/^\d{10}$/},
{name:'United States',nameInLocalLang:'United States',code:'us',dialCode:'+1',flag:'https://flagcdn.com/w320/us.png',pattern:/^\d{10}$/},
{name:'Uruguay',nameInLocalLang:'Uruguay',code:'uy',dialCode:'+598',flag:'https://flagcdn.com/w320/uy.png',pattern:/^\d{8}$/},
{name:'Uzbekistan',nameInLocalLang:'Oʻzbekiston',code:'uz',dialCode:'+998',flag:'https://flagcdn.com/w320/uz.png',pattern:/^\d{9}$/},
{name:'Vanuatu',nameInLocalLang:'Vanuatu',code:'vu',dialCode:'+678',flag:'https://flagcdn.com/w320/vu.png',pattern:/^\d{6}$/},
{name:'Vatican City',nameInLocalLang:'Città del Vaticano',code:'va',dialCode:'+379',flag:'https://flagcdn.com/w320/va.png',pattern:/^\d{4}$/},
{name:'Venezuela',nameInLocalLang:'Venezuela',code:'ve',dialCode:'+58',flag:'https://flagcdn.com/w320/ve.png',pattern:/^\d{10}$/},
{name:'Vietnam',nameInLocalLang:'Việt Nam',code:'vn',dialCode:'+84',flag:'https://flagcdn.com/w320/vn.png',pattern:/^\d{10}$/},
{name:'Yemen',nameInLocalLang:'اليمن',code:'ye',dialCode:'+967',flag:'https://flagcdn.com/w320/ye.png',pattern:/^\d{9}$/},
{name:'Zambia',nameInLocalLang:'Zambia',code:'zm',dialCode:'+260',flag:'https://flagcdn.com/w320/zm.png',pattern:/^\d{10}$/},
{name:'Zimbabwe',nameInLocalLang:'Zimbabwe',code:'zw',dialCode:'+263',flag:'https://flagcdn.com/w320/zw.png',pattern:/^\d{9,10}$/}
];
