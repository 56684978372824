import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ABService, ApiService, EventsService, PaymentService, ScriptLoaderService, SeoService, StorageService, UtilService, VariablesService } from './shared/services';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from './shared/shared.module';
import { HttpInterService } from './shared/services/http-inter/http-inter.interceptor';
import { ErrorHandlerModule } from './shared/error-handler/error-handler.module';
import { environment } from 'src/environments/environment';

const Services = [
  ABService,
  ApiService,
  EventsService,
  PaymentService,
  ScriptLoaderService,
  SeoService,
  StorageService,
  UtilService,
  VariablesService
];

const sharedModules = [
  MatDialogModule,
  MatSnackBarModule,
  MatSelectModule,
  MatBottomSheetModule,
  environment.production ? ErrorHandlerModule : []
]

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'equalall' }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    ...sharedModules
  ],
  providers: [
    ...Services,
    HttpInterService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
