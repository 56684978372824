import { DOCUMENT, NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { Component, Inject, Input, Renderer2, SimpleChange } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IUser } from 'src/app/shared/model/user.model';
import { UtilService, VariablesService } from 'src/app/shared/services';

@Component({
  selector: 'app-profile-progress',
  standalone: true,
  imports: [
    NgIf,
    NgStyle,
    NgOptimizedImage,
    MatIconModule,
  ],
  templateUrl: './profile-progress.component.html',
  styleUrls: ['./profile-progress.component.scss']
})
export class ProfileProgressComponent {
  dashArray: number = 2 * Math.PI * 60;
  dashOffset: number =  this.dashArray;  // Initialize the offset to full circumference
  animationStyle: any = {'stroke-dashoffset': this.dashOffset};
  @Input() percentage: any = 50;
  userData: IUser | undefined;
  staticCircleCompleteValues: any = {
    100: 120,
    70: 200,
    55: 300,
    60: 205,
    30: 422
  }

  initials = '';

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private util: UtilService,
    private vars: VariablesService
  ) {}

  ngOnInit() {
    this.setProfileProgressBar();
    if (this.userData) {
      this.initials = this.util.getInitialsOfName(this.userData?.full_name || '').toUpperCase();
    }
    this.getUserData();
  }

  getUserData() {
    this.vars.userData$.subscribe((val: any) => {
      this.userData = val;
    })
  }

  ngOnChanges(changes: any) {
    if (changes.percentage.currentValue) {
      this.setProfileProgressBar();
      this.getUserData();
    }
  }

  setProfileProgressBar() {
    if (this.percentage) {
      let finalOffset = this.dashArray * (1 - this.percentage / 100);
      if (this.percentage in this.staticCircleCompleteValues) {
        finalOffset = this.staticCircleCompleteValues[this.percentage];
      }
      if (typeof document !== 'undefined') {
        setTimeout(() => {
          this.dashOffset = finalOffset;
          this.addCustomAnimation(finalOffset);
        }, 0);
      }
    }
  }

  
  addCustomAnimation(offset: number) {
    const duration = '1s';  // You can change this to adjust the speed of the animation
    const easing = 'ease-in-out';  // Adjust the easing function if needed

    // Dynamically set the animation style string
    this.animationStyle = `anim ${duration} ${easing} forwards`;
    const styleSheet = this.renderer.createElement('style');
    styleSheet.type = 'text/css';
    const keyframes = `
      @keyframes anim {
        100% {
          stroke-dashoffset: ${offset};
        }
      }
    `;
    styleSheet.innerHTML = keyframes;
    this.renderer.appendChild(this.document.head, styleSheet);
  }
}
