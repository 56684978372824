
<app-header *ngIf="vars.pageLayoutConfig?.showHeader"/>

<app-header-v2 *ngIf="vars.pageLayoutConfig?.showHeaderV2" />

<div *ngIf="isSidebarOpened" class="blur-bg" (click)="closeProfileSidebar()"></div>

<router-outlet />

<app-footer *ngIf="vars.pageLayoutConfig?.showFooter" />
<app-mini-footer *ngIf="vars.pageLayoutConfig?.showMiniFooter"/>