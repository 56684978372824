<div class="footer-wrap">
  <div class="content">
    <div class="section-1">
      <div *ngIf="!vars.isMobile" class="support-details">
        <span class="title">For any queries</span>
        <span class="email">Email: <a style="cursor: pointer;" href="mailto:support@equalall.org">support&#64;equalall.org</a></span>
        <span class="phone">Contact No: +1 3322698250</span>
      </div>
      <div *ngIf="!vars.isMobile" class="logo">
        <div class="imgHolder"><img class="brand-logo" src="assets/images/footer/footer-logo.png" [alt]="vars.domain_details.name" priority /></div>
        <div class="social-icons">
          <img *ngFor="let item of socialhandlers" (click)="linkhandler(item.clickUrl || '')" [ngSrc]="item.logopath" [width]="16" [height]="16">
          <span class="followers">200K+ <span>Followers</span></span>
        </div>
      </div>
      <div *ngIf="vars.isMobile" class="support-details">
        <span class="title">Subscribe and Follow us on</span>
      </div>
      <div *ngIf="vars.isMobile" class="logo">
        <div class="social-icons">
          <img *ngFor="let item of socialhandlers" (click)="linkhandler(item.clickUrl || '')" [ngSrc]="item.logopath" [width]="16" [height]="16">
          <span class="followers">200K+ <span>Followers</span></span>
        </div>
        <div class="imgHolder"><img class="brand-logo" src="assets/images/footer/footer-logo.png" [alt]="vars.domain_details.name" priority /></div>
      </div>
    </div>
    <div class="section-2">
      <div class="policies">
        <div class="terms-policy">
          <span><a style="text-decoration: none;" href="https://app.termageddon.com/api/policy/VmtOVlMwbFFhRWd6VTFKM2VtYzlQUT09?h-align=left&table-style=accordion" target="_blank">Terms of Service</a></span>|
          <span><a style="text-decoration: none;" href="https://app.termageddon.com/api/policy/VVZWTE0zUm1UWEZyUkdkelluYzlQUT09?h-align=left&table-style=accordion" target="_blank">Privacy Policy</a></span>|
          <span routerLink="/aboutus">About Us</span>
        </div>
        <div class="terms-policy">
          <span><a style="text-decoration: none;" rel="nofollow" href="https://app.termageddon.com/api/policy/UlZaM1MwTlpWWEYzTkZKb1MxRTlQUT09?css-compatibility=true&h-align=left&table-style=accordion" target="_blank">Use of Cookies</a></span>|
          <span routerLink="/guarantee">equalall Guarantee</span>|
          <span routerLink="/success-stories">Our Impact</span>
        </div>
      </div>
      <div class="payment-terms">
        <img class="payment-logo" src="assets/images/footer/paypalstripe.svg" alt="visa mastercard logo"/>
        <div class="secure"><img class="secure-logo" src="assets/images/lock.png" alt="visa mastercard logo"/><span>100% Secure</span></div>
      </div>
    </div>
    <div class="section-3">
      <div class="copyright">Copyright © {{currentYear}} equalall. All Rights Reserved.</div>
      <div class="disclaimer">
        equalall (Equal Aid for Equal Lives, Inc) is a California-registered 501(c)(3) nonprofit organization (EIN 93-369588).
      </div>
    </div>
  </div>
</div>