export const environment = {
  name: 'prod',
  production: true,
  API_BASE_URL: 'https://www.equalall.org/api/',
  DOMAIN_URL: 'https://www.equalall.org',
  bugsnag: '03a913f6a9d88469cd0fc21e5f030643',
  clevertap_id: 'RKW-6ZK-4R7Z',
  facebook_id: '287403324698248',
  facebook_pixel: '441308802710601',
  firebase: {
    apiKey: "AIzaSyAX5a0Zxr6z5NpEmdYSeRa7uFt5mwwoBtw",
    authDomain: "equalall.firebaseapp.com",
    projectId: "equalall",
    storageBucket: "equalall.appspot.com",
    messagingSenderId: "877800640360",
    appId: "1:877800640360:web:b29713bc8aee2538b010dd",
    measurementId: "G-0NMJ8CHJZP"
  },
  gcaptcha: '6Lemuy0UAAAAAAJL2gRKu1BzpYtg5oI8QJCT89bI',
  google_client_id: '877800640360-ng6oea68ku6keh9qo66enh436t16ojik.apps.googleusercontent.com',
  google_client_id_one_tap: '877800640360-ng6oea68ku6keh9qo66enh436t16ojik.apps.googleusercontent.com',
  gtm_id: 'GTM-WDBN2F2F',
  gtm_ids: <any>{
    'equalall': 'GTM-WDBN2F2F'
  },
  invisible_captcha: '6LcYhV8UAAAAABM9Km9bMITOAJyPEJNPM3uYnuQL',
  microsoft_clarity: 'nr5a4ti0hm',
  puhser_key: 'e8bdd5e81bddad457212',
  meilisearch: {
    host: 'https://msearch.ketto.org',
    key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZWFyY2hSdWxlcyI6eyJwcm9kX2Rpc2Vhc2VzIjp7fSwicHJvZF9ob3NwaXRhbHMiOnt9LCJmdW5kcmFpc2VyX3Byb2QiOnsiZmlsdGVyIjoicGFyZW50X2NhdXNlX2lkICE9IDE0OSJ9fSwiYXBpS2V5VWlkIjoiMjk0YjYzMTAtZjdiNC00MDdiLTg2MWYtMGQ0MTJmMzdkZjc4IiwiaWF0IjoxNjc2ODg3NzkzfQ.CXJdTbdG2JMQPQYnCuXsrv0oFlOjDs8hO3w0Rep4lUE',
    index: 'fundraiser_prod',
    disease: {
      index: 'prod_diseases',
      not_listed: 4562
    },
    hospital: {
      index: 'prod_hospitals',
      not_listed: 1734593,
      not_admitted: 1734594,
      no_hospitalisation_required: 1734595
    }
  },
  truecaller: {
    partnerKey: 'IYGXye7642585d0924b7086aff71119217401',
  },
  health_first_campaign_id: 787165,
  health_first_ct: 'offer-a-helping-hand-to-support-saurabh-rs-treatment',
  campaign_url: "/stories/northgazaneedshelp"
};
