<div class="profile-progress-wrapper">
    <div class="outer-circle">
        <div class="inner-circle">
            <img *ngIf="userData?.avtar" src="{{userData?.avtar?.cdn_path}}" alt="user img" />
            <div class="initials" *ngIf="userData?.avtar === null && initials">
                {{initials}}
            </div>
            <mat-icon *ngIf="userData?.avtar === null && !initials" class="person-icon">person</mat-icon>
        </div>
        <div class="progress-bar">
            <svg width="134px" height="134px">
                <defs>
                    <linearGradient id="linearGradient">
                        <stop offset="0%" stop-color="#2A7AAC"/>
                        <stop offset="100%" stop-color="#2A7AAC"/>
                    </linearGradient>
                </defs>
                <circle cx="67px" cy="67px" r="60"
                [attr.stroke-dasharray]="dashArray"
                [attr.stroke-dashoffset]="dashOffset"
                [ngStyle]="{'animation': animationStyle}"></circle>
            </svg>
        </div>
    </div>
</div>